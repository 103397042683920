import React, { createContext, useEffect, useState, useCallback } from 'react'
import IUser from '@interfaces/IUser'
import { Outlet, useLocation } from 'react-router-dom'
import { useMutation } from 'react-query'
import * as $Auth from '@services/Auth'
import Loading from '@components/Loading/Loading'
import IClient from '@interfaces/IClient'
import ISchool from '@interfaces/ISchool'
import axios from 'axios'
import useTheme from '@hooks/useTheme'
import useStickyState from '@hooks/useStickyState'

type AuthContextProps = {
  user: IUser|null
  client: IClient|null
  school: ISchool|null
  setSchool: React.Dispatch<React.SetStateAction<ISchool|null>>
  year: number
  setYear: React.Dispatch<React.SetStateAction<number>>
  platformKeys: any
  isAuthenticated: boolean
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  login: (token: string, onSuccess?: () => void) => void
  logout: () => void
}

const simulados = 'https://simulados.evolucional.com.br/'

const AuthContext = createContext<AuthContextProps>({ } as AuthContextProps)

export const AuthProvider: React.FC<any> = () => {
  const [ user, setUser ] = useState<IUser|null>(null)
  const [ platformKeys, setPlatformKeys ] = useState<any>({})
  const [ client, setClient ] = useState<IClient|null>(null)
  const [ school, setSchool ] = useState<ISchool|null>(null)
  const [ year, setYear ] = useStickyState<number>(new Date().getFullYear(), 'year')
  const [ isAuthenticated, setIsAuthenticated ] = useState<boolean>(false)
  const [ isLoading, setIsLoading ] = useState<boolean>(true)

  const location = useLocation()

  useTheme(client)

  const me = useMutation($Auth.me)

  const login = useCallback((token: string, onSuccess?: () => void) => me.mutate(token, {
    onSuccess: ({ data: { user, platformKeys, school, client } }: any) => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      axios.defaults.headers.common['SchoolId'] = school.id
      axios.defaults.headers.common['Year'] = year

      if (!school.years.includes(year)) {
        axios.defaults.headers.common['Year'] = school.year
        setYear(school.year)
      }

      setUser(user)
      setClient(client)
      setSchool(school)
      setIsAuthenticated(true)
      setIsLoading(false)
      setPlatformKeys({
        FLEXMONSTER: process.env.REACT_APP_FLEXMONSTER_LICENSE_KEY,
        ...platformKeys,
      })

      onSuccess?.()
    },
    onError: () => {
      window.location.href = simulados
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [])

  useEffect(() => {
    if (location.pathname.startsWith('/login'))
      return

    const token = localStorage.getItem('token')

    if (token && token.length > 0) {
      login(token)
    } else {
      window.location.href = simulados
    }
  }, [location.pathname, login])

  const logout = () => {
    setUser(null)
    setIsAuthenticated(false)

    localStorage.clear()
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        client,
        school,
        setSchool,
        year,
        setYear,
        platformKeys,
        isAuthenticated,
        isLoading,
        setIsLoading,
        logout,
        login,
      }}
    >
      <Loading isLoading={isLoading} />
      <Outlet />
    </AuthContext.Provider>
  )
}

export default AuthContext
