import React, { useContext, useEffect } from 'react'
import AuthContext from '@contexts/Auth'
import { useLocation, useNavigate } from 'react-router-dom'

const simulados = 'https://simulados.evolucional.com.br/'

const Login: React.FC<any> = () => {
  const { login } = useContext(AuthContext)

  const { search } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const params = new URLSearchParams(search)

    if (params.has('token')) {
      const token: string = params.get('token') as string

      login(token, () => {
        localStorage.setItem('token', token)
        navigate('/')
      })
    } else {
      window.location.href = simulados
    }
  }, [login, navigate, search])

  return null
}

export default Login
