import styled from 'styled-components'

export const Sort = styled.div`
  width: 100%;

  > .btn {
    background-color: #fff;
    flex-grow: 1;
    flex-basis: 0;
  }
`
