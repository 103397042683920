import React, { ReactNode } from 'react'
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa'

type Props = {
  getTableProps: () => any
  getTableBodyProps: () => any
  page: any[]
  headerGroups: any[]
  prepareRow: any
  isLoading: boolean
  emptyMessage?: ReactNode
}

const Table: React.FC<any> = ({ getTableProps, getTableBodyProps, page, headerGroups, prepareRow, isLoading, emptyMessage }: Props) => {
  return (
    <table {...getTableProps()} className="table table-striped table-md mb-0">
      <thead>
        {headerGroups.map((headerGroup: any) => {
          const { key, ...props } = headerGroup.getHeaderGroupProps()

          return (
            <tr {...props} key={key}>
              {headerGroup.headers.map((column: any) => (
                <th
                  {...column.getHeaderProps({
                    ...column.getSortByToggleProps(),
                    style: {
                      ...column.getHeaderProps().style,
                      ...column.style,
                    }
                  })}
                  className={column.canSort ? 'text-nowrap' : ''}
                  disabled={isLoading}
                  key={column.id}
                >
                  {column.render('Header')}
                  {column.canSort && (!column.isSorted ? (
                    <FaSort className="ms-1" size={9} color="#707ca1" />
                  ) : column.isSortedDesc ? (
                    <FaSortDown className="ms-1" size={9} color="#00b7ff" />
                  ) : (
                    <FaSortUp className="ms-1" size={9} color="#00b7ff" />
                  ))}
                </th>
              ))}
            </tr>
          )
        })}
      </thead>

      <tbody {...getTableBodyProps()}>
        {page.length > 0 ? page.map((row: any) => {
          prepareRow(row)

          return (
            <tr {...row.getRowProps()} key={row.id}>
              {row.cells.map((cell: any, index: number) => (
                <td
                  align="middle"
                  className="text-elipsis"
                  {...cell.getCellProps({
                    style: {
                      ...cell.getCellProps().style,
                      ...cell.column.style,
                    },
                  })}
                  key={`cell-${index}`}
                >
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          )
        }) : (
          <tr>
            <td colSpan={headerGroups[0].headers.length} className="text-center">
              {isLoading ? 'Aguarde...' : emptyMessage || 'Nenhum registro encontrado'}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default Table
