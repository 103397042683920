import useStickyState from '@hooks/useStickyState'
import React, { createContext } from 'react'
import { useQuery } from 'react-query'
import * as $ReportmentCategory from '@services/ReportmentCategory'
import * as $Platform from '@services/Platform'
import { AxiosResponse } from 'axios'
import IPlatform from '@interfaces/IPlatform'
import IReportmentCategory from '@interfaces/IReportmentCategory'
import ISchool from '@interfaces/ISchool'

type PanelContextProps = {
  sortBy: string
  setSortBy: React.Dispatch<React.SetStateAction<string>>
  categories: AxiosResponse<IReportmentCategory[], any> | undefined
  platforms: AxiosResponse<IPlatform[], any> | undefined
  collapsed: boolean
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>
}

type Props = {
  year: number
  school: ISchool
  children: React.ReactNode
}

const PanelContext = createContext<PanelContextProps>({ } as PanelContextProps)

export const PanelProvider: React.FC<Props> = ({ year, school, children }: Props) => {
  const [ sortBy, setSortBy ] = useStickyState<string>('alphabetical', 'sortBy')
  const [ collapsed, setCollapsed ] = useStickyState<boolean>(false, 'collapsed')

  const { data: platforms } = useQuery([ 'platforms', school.id ], $Platform.all, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
  })

  const { data: categories } = useQuery([ 'categories', Number(school.id), Number(year) ], $ReportmentCategory.all, {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
  })

  return (
    <PanelContext.Provider
      value={{
        sortBy,
        setSortBy,
        categories,
        platforms,
        collapsed,
        setCollapsed,
      }}
    >
      {children}
    </PanelContext.Provider>
  )
}

export default PanelContext
