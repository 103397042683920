import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import axios from 'axios'
import ISchool from '@interfaces/ISchool'
import Router from './routes/Router'

import 'flexmonster/flexmonster.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-tooltip/dist/react-tooltip.css'
import './resources/scss/app.scss'

const token: string = localStorage.getItem('token') || ''
const school: ISchool = JSON.parse(localStorage.getItem('school') || '{}')
const year: number = Number(localStorage.getItem('year')) || new Date().getFullYear()

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
axios.defaults.headers.common['SchoolId'] = school?.id
axios.defaults.headers.common['Year'] = year
axios.defaults.headers.common['PlatformId'] = 9

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <Router />
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
