import React, { useContext } from 'react'
import Header from '@components/Header/Header'
import { Outlet } from 'react-router-dom'
import Sidebar from '@components/Sidebar/Sidebar'
import PanelContext from '@contexts/Panel'
import { ContainerFluid } from './App.styles'

const App: React.FC<any> = () => {
  const { collapsed } = useContext(PanelContext)

  return (
    <>
      <Header />

      <ContainerFluid className="container-fluid">
        <div className="row">
          <Sidebar />

          <main className={'main col-12 ' + (collapsed ? 'col-md-11 collapsed' : 'col-md-10')}>
            <Outlet />
          </main>
        </div>
      </ContainerFluid>
    </>
  )
}

export default App
