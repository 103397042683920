import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Spinner, Tab, Tabs } from 'react-bootstrap'
import ReportmentContext from '@contexts/Reportment'
import { AiOutlineDownload } from 'react-icons/ai'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import * as $Reportment from '@services/Reportment'
import Flexmonster from './Flexmonster/Flexmonster'
import { useMutation } from 'react-query'
import { exportCSV, tryParseJSONObject } from '@helpers/Utils'
import Data from './Data/Data'
import IChart from '@interfaces/IChart'

const localization = require('@resources/lang/flexmonster/pt.json')

const Reportment: React.FC<any> = () => {
  const { reportmentId, reportment, charts } = useContext(ReportmentContext)

  const [ activeKey, setActiveKey ] = useState<string>('')

  const { categoryId } = useParams()

  const chartRefs = useRef<any[]>([])
  const SweetAlert = withReactContent(Swal)

  const mutations = {
    download: useMutation($Reportment.download),
    visits: useMutation($Reportment.visits),
  }

  useEffect(() => {
    if (reportmentId) {
      mutations.visits.mutate(reportmentId)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportmentId])

  useEffect(() => {
    if (activeKey === '' && charts && charts.data.length > 0) {
      setActiveKey(`${charts.data[0].id}`)
    }
  }, [activeKey, charts])

  const download = () => {
    if (!reportment)
      return

    SweetAlert.fire({
      title: 'Aguarde, gerando relatório...',
      text: 'Essa atividade pode demorar alguns minutos dependendo do relatório.',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    mutations.download.mutate(reportmentId, {
      onSuccess: ({ data }) => {
        exportCSV(data, `${reportment.data.name}.csv`)

        SweetAlert.fire({
          title: 'Relatório gerado com sucesso!',
          text: 'Arquivos XLSX são formatados utilizando a separação por vírgula.',
          icon: 'success',
          didOpen: () => SweetAlert.hideLoading(),
        })
      },
      onError: () => {
        SweetAlert.fire({
          title: 'Erro!',
          text: 'Não foi possível exportar o relatório, tente novamente mais tarde.',
          icon: 'error'
        })
      },
    })
  }

  const onSelect = (key: string) => {
    if (!charts)
      return

    setActiveKey(key)

    const index = charts.data.findIndex(({ id }) => id.toString() === key)

    if (index !== -1)
      setTimeout(() => chartRefs.current[index].refresh(), 100)
  }

  return (
    <>
      <div className="row align-items-center">
        <div className="col-12 col-md-6 mb-3">
          <h1 className="mb-0">
            {reportment?.data.name ?? 'Carregando...'}
          </h1>

          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item"><Link to="/">Painel</Link></li>
            <li className="breadcrumb-item"><Link to={`/categorias/${categoryId}/relatorios`}>{reportment?.data.category?.name}</Link></li>
            <li className="breadcrumb-item active" aria-current="page">{reportment?.data.name ?? 'Relatório'}</li>
          </ol>
        </div>

        <div className="col-12 col-md-6 d-flex justify-content-end mb-3">
          <Link to="/relatorios" className="btn btn-outline-primary pe-3 ps-3 me-2">Voltar</Link>
          {charts && charts.data.length === 0 && (
            <button className="btn btn-primary pe-3 ps-3" disabled={!reportment?.data.id} onClick={download}>
              Exportar relatório <AiOutlineDownload size={18} />
            </button>
          )}
        </div>
      </div>

      {reportment?.data.canPreview ? (
        <>
          {charts && charts.data.length > 0 ? (
            <Tabs activeKey={activeKey} onSelect={(key: any) => onSelect(key)}>
              {charts.data.map((chart: IChart) => {
                const options = tryParseJSONObject(chart.options) ?? {}

                options.showAggregationLabels = false

                return (
                  <Tab eventKey={chart.id} title={chart.name} key={chart.id}>
                    <div className="card">
                      <div className="card-body">
                        <Flexmonster
                          ref={(ref: any) => chartRefs.current.push(ref)}
                          style={chart.style}
                          name={chart.name}
                          expandAllData={chart.expandAllData}
                          expandLabel={chart.expandLabel}
                          report={{
                            dataSource: {
                              data: reportment.data.data,
                            },
                            slice: tryParseJSONObject(chart.slice) ?? {},
                            formats: tryParseJSONObject(chart.formats) ?? {},
                            options,
                            localization,
                          }}
                        />
                      </div>
                    </div>
                  </Tab>
                )
              })}
            </Tabs>
          ) : (
            <Data />
          )}
        </>
      ) : (
        <div className="card">
          <div className="card-body">
            {reportment?.data.id ? (
              <div className="bg-light text-center p-3">
                Para visualizar o relatório é necessário exportá-lo.
                <br/>
                <button className="btn btn-link" disabled={!reportment?.data.id} onClick={download}>
                  Exportar relatório <AiOutlineDownload size={18} />
                </button>
              </div>
            ) : (
              <div className="d-flex justify-content-center p-3 bg-light">
                <Spinner animation="border" variant="primary" />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default Reportment
