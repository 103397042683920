import axios, { AxiosResponse } from 'axios'
import IPlatform from '@interfaces/IPlatform'
import { QueryFunction } from 'react-query'

export const all: QueryFunction<AxiosResponse<IPlatform[]>> = () => axios.get('platforms')

export const redirectUrl = (schoolId: number, userId: number, platform: IPlatform): Promise<any> => {
  return axios.post(`platforms/${platform.id}/redirect-url`, {
    userId,
    schoolId,
    assessmentModelId: platform.assessmentModelId,
  })
}
