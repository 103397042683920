import React, { ComponentPropsWithRef, ElementType } from 'react'
import { ReactComponent as Spinner } from '@resources/svg/spinner.svg'
import { Overlay } from './Loading.styles'

type Props = ComponentPropsWithRef<ElementType> & {
  isLoading: boolean
}

const Loading: React.FC<Props> = ({ isLoading, className, ...props }: Props) => {
  return (
    <Overlay className={(className ?? '') + (isLoading ? ' is-loading' : '')} {...props}>
      <div className="overlay-container">
        <div className="spinner">
          <Spinner />
        </div>
      </div>
    </Overlay>
  )
}

export default Loading
