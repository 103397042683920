import * as XLSX from 'xlsx'

export const random = (): number => parseInt((Math.random() * 100000).toFixed(0))

export const generateToken = (size: number): string => {
  return new Array(size).join().replace(/(.|$)/g, () => ((Math.random()*36)|0).toString(36)[Math.random()<.5?'toString':'toUpperCase']())
}

export const toBRLPrice = (price: number) => {
  if (isNaN(price))
    return '0,00'

  return new Intl.NumberFormat('pt-BR', {
    currency: 'BRL',
    minimumFractionDigits: 2,
  }).format(price)
}

export const downloadDataURL = (dataURL: string, fileName: string) => {
  const link = document.createElement('a')
  link.download = fileName
  link.href = dataURL
  link.target = '_blank'
  link.click()
}

export const decompose = (matrix: number[]) => {
  const a = matrix[0]
  const b = matrix[1]
  const c = matrix[2]
  const d = matrix[3]
  const e = matrix[4]
  const f = matrix[5]

  const delta = a * d - b * c

  const result = {
    x: e,
    y: f,
    rotation: 0,
    scaleX: 0,
    scaleY: 0,
    skewX: 0,
    skewY: 0,
  }

  if (a != 0 || b != 0) {
    const r = Math.sqrt(a * a + b * b)
    result.rotation = b > 0 ? Math.acos(a / r) : -Math.acos(a / r)
    result.scaleX = r
    result.scaleY = delta / r
    result.skewX = Math.atan((a * c + b * d) / (r * r))
    result.skewY = 0
  } else if (c != 0 || d != 0) {
    const s = Math.sqrt(c * c + d * d)
    result.rotation = Math.PI / 2 - (d > 0 ? Math.acos(-c / s) : -Math.acos(c / s))
    result.scaleX = delta / s
    result.scaleY = s
    result.skewX = 0
    result.skewY = Math.atan((a * c + b * d) / (s * s))
  }

  result.rotation *= 180 / Math.PI

  return result
}

export const grade = (grade: number): number => {
  if (isNaN(grade)) {
    return 0
  }

  return parseFloat(grade?.toFixed(1)?.replace(/\.0+$/, ''))
}

export const exportCSV = (data: string, filename: string) : void => {
  const downloadLink = document.createElement('a')
  const blob = new Blob(['\ufeff', data])
  const url = URL.createObjectURL(blob)
  downloadLink.href = url
  downloadLink.download = filename

  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}

export const exportXLSX = (data: string[][], sheet: string, filename: string) : void => {
  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.aoa_to_sheet(data)
  XLSX.utils.book_append_sheet(wb, ws, sheet)
  XLSX.writeFile(wb, filename)
}

export const youtubeEmbedId = (url: string): string => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = url.match(regExp)

  return (match && match[7].length === 11) ? match[7] : ''
}

export const hexToRgb = (hex: string): string => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  if (!result)
    return '0, 0, 0'

  const r = parseInt(result[1], 16)
  const g = parseInt(result[2], 16)
  const b = parseInt(result[3], 16)

  return `${r}, ${g}, ${b}`
}

export const tryParseJSONObject = (json: string): any => {
  if (typeof json !== 'string' || json.length === 0 || json === undefined || json === null || json === 'null' || json === 'undefined')
    return false

  try {
    const o = JSON.parse(json)

    if (o && (typeof o === 'object'))
      return o
  } catch {
    return false
  }
}
