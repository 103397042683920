import styled, { createGlobalStyle } from 'styled-components'

type Props = {
  custom: string
}

export const GlobalStyle = createGlobalStyle<Props>`
  ${props => props.custom}
`

export const Form = styled.form`
  z-index: 99;
`
