import { MutationFunction, QueryFunction } from 'react-query'
import IReportment from '@interfaces/IReportment'
import axios, { AxiosResponse } from 'axios'

export const all: QueryFunction<AxiosResponse<IReportment[]>, [ string, number, number ]> = ({ queryKey: [ , , year ] }) => {
  return axios.get('reportments', {
    params: {
      year,
    },
  })
}

export const find: QueryFunction<AxiosResponse<IReportment>, [string, number]> = ({ queryKey: [ , id ] }) => axios.get(`reportments/${id}`, {
  timeout: 180000,
})

export const data: MutationFunction<AxiosResponse<any>, [number, any]> = ([reportmentId, filters]) => axios.get(`reportments/${reportmentId}/data`, {
  timeout: 180000,
  params: {
    filters,
  },
})

export const download: MutationFunction<AxiosResponse<any>, number> = (reportmentId: number) => axios.post(`reportments/${reportmentId}/download`)

export const visits: MutationFunction<AxiosResponse<any>, number> = (reportmentId: number) => axios.post(`reportments/${reportmentId}/visits`)
