import IReportmentCategory from '@interfaces/IReportmentCategory'
import axios, { AxiosResponse } from 'axios'
import { QueryFunction } from 'react-query'

export const all: QueryFunction<AxiosResponse<IReportmentCategory[]>, [ string, number, number ]> = ({ queryKey: [ , , year ] }) => {
  return axios.get('reportments/categories', {
    params: {
      year,
    },
  })
}
