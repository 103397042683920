import ISchool from '@interfaces/ISchool'
import axios, { AxiosResponse } from 'axios'
import { MutationFunction } from 'react-query'

export const me: MutationFunction<AxiosResponse, string> = (token) => axios.get('auth/me', {
  headers: {
    Authorization: `Bearer ${token}`,
  },
})

export const setSchool: MutationFunction<AxiosResponse, ISchool> = (school) => axios.post(`auth/set-default-school/${school.id}`)
