import ISchool from '@interfaces/ISchool'
import axios, { AxiosResponse } from 'axios'
import { MutationFunction, QueryFunction } from 'react-query'

export const all: QueryFunction<AxiosResponse<ISchool[]>> = () => axios.get('schools', {
  params: {
    platformId: 9,
  },
})

export const years: MutationFunction<AxiosResponse<number[]>, ISchool> = (school) => axios.get(`schools/${school.id}/years`)

export const find: QueryFunction<AxiosResponse<ISchool>, [number]> = ({ queryKey: [ id ] }) => axios.get(`schools/${id}`)
