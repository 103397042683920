import React, { useContext, useState } from 'react'
import { Container, Figure } from './Header.styles'
import { ReactComponent as User } from '@resources/svg/user.svg'
import { Link } from 'react-router-dom'
import { Dropdown, Spinner } from 'react-bootstrap'
import Logotipo from '@components/Logotipo/Logotipo'
import Select from 'react-select'
import AuthContext from '@contexts/Auth'
import IPlatform from '@interfaces/IPlatform'
import * as $School from '@services/School'
import * as $Auth from '@services/Auth'
import * as $Platform from '@services/Platform'
import { useMutation, useQuery } from 'react-query'
import ISchool from '@interfaces/ISchool'
import axios, { AxiosResponse } from 'axios'
import PanelContext from '@contexts/Panel'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

const Header: React.FC<any> = () => {
  const { collapsed, platforms } = useContext(PanelContext)
  const { user, client, school, year, setYear, logout, setIsLoading } = useContext(AuthContext)

  const [ schools, setSchools ] = useState<ISchool[]>([])
  const [ isRedirectLoading, setIsRedirectLoading ] = useState<boolean>(false)

  const SweetAlert = withReactContent(Swal)

  const $update = useMutation($Auth.setSchool)

  useQuery('schools', $School.all, {
    onSuccess: ({ data: schools }: AxiosResponse<ISchool[]>) => setSchools(schools),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  const handleSchoolChange = ({ value: schoolId }: any) => {
    const school = schools.find((school: ISchool) => school.id == schoolId)

    if (school) {
      setIsLoading(true)

      axios.defaults.headers.common['SchoolId'] = school.id

      $update.mutateAsync(school).then(({ data: { token } }) => {
        localStorage.setItem('token', token)
        localStorage.setItem('school', JSON.stringify(school))

        setTimeout(() => {
          window.location.reload()
        }, 200)
      }).catch(() => setIsLoading(false))
    }
  }

  const handleYearChange = ({ value: year }: any) => {
    axios.defaults.headers.common['Year'] = year
    setYear(year)
  }

  const redirectTo = (platform: IPlatform) => {
    setIsRedirectLoading(true)

    if (!school || !user) return

    $Platform.redirectUrl(school.id, user.id as number, platform).then(({ data }: any) => {
      if (data?.ResultAccessUrl && data.ResultAccessUrl.length > 0) {
        window.location.href = data.ResultAccessUrl[0]
      } else if (data?.redirectUrl) {
        window.location.href = data.redirectUrl
      }
    }).catch(() => {
      setIsRedirectLoading(false)

      SweetAlert.fire({
        title: 'Ops!',
        text: 'Não é possível acessar essa plataforma no momento, tente novamente mais tarde.',
        icon: 'error'
      })
    })
  }

  return (
    <Container>
      <div className="container-fluid">
        <div className="row">
          <Figure className={'logotipo col-3 col-sm-2 ' + (collapsed ? 'col-md-1 collapsed' : 'col-md-2')}>
            <Link to="/">
              {client && (client.relativeLogotipoColors ? (
                <>
                  <Logotipo
                    width={200}
                    height={60}
                    className={'logotipo logotipo-full' + (collapsed ? '' : ' active')}
                    src={client.logotipoUrl}
                    color={client.primaryColor}
                  />

                  <Logotipo
                    width={135}
                    height={60}
                    className={'logotipo logotipo-min' + (collapsed ? ' active' : '')}
                    src={client.logotipoMinUrl}
                    color={client.primaryColor}
                  />
                </>
              ) : (
                <>
                  <img
                    src={client.logotipoUrl}
                    alt={client.name}
                    className={'logotipo logotipo-full' + (collapsed ? '' : ' active')}
                  />

                  <img
                    src={client.logotipoMinUrl}
                    alt={client.name}
                    className={'logotipo logotipo-min' + (collapsed ? ' active' : '')}
                  />
                </>
              ))}
            </Link>
          </Figure>

          <div className={'header-bar col-9 col-sm-10 ' + (collapsed ? 'col-md-11' : 'col-md-10')}>
            <div className="env-selector">
              {school && (
                <>
                  <div className="select-field select-schools me-3">
                    <Select
                      placeholder="Selecione a escola"
                      onChange={handleSchoolChange}
                      defaultValue={school && {
                        label: school.fantasyName,
                        value: school.id,
                      }}
                      options={schools.map(school => ({
                        label: school.fantasyName,
                        value: school.id,
                      }))}
                      isSearchable
                    />
                  </div>

                  <div className="select-field select-years">
                    <Select
                      placeholder="Selecione o ano"
                      onChange={handleYearChange}
                      options={school.years.map(year => ({
                        label: year,
                        value: year,
                      }))}
                      defaultValue={year && {
                        label: year,
                        value: year,
                      }}
                      isSearchable
                    />
                  </div>
                </>
              )}
            </div>

            <div className="menu">
              <div className="user">
                <Dropdown>
                  <Dropdown.Toggle variant="light">
                    <User />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {school && (
                      <Dropdown.Item className="text">
                        <small>ID Escola</small>
                        {school.id}
                      </Dropdown.Item>
                    )}

                    {user && (
                      <>
                        <Dropdown.Item className="text">
                          <small>ID Usuário</small>
                          {user.id}
                        </Dropdown.Item>

                        <Dropdown.Item className="text">
                          {user.name}
                        </Dropdown.Item>
                      </>
                    )}

                    <Dropdown.Divider />

                    <Dropdown.Item onClick={logout}>Sair</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="platform" style={{ cursor: isRedirectLoading ? 'wait' : 'default' }}>
                <Dropdown>
                  <Dropdown.Toggle variant="danger">
                    Relatórios {isRedirectLoading && <Spinner animation="border" variant="light" size="sm" className="ms-2" />}
                  </Dropdown.Toggle>

                  {platforms && (
                    <Dropdown.Menu>
                      {platforms.data.filter(({ id, displayRules }) => (
                        displayRules.includes('MENU') && id !== 9
                      )).map((platform: IPlatform) => (
                        <Dropdown.Item onClick={() => redirectTo(platform)} key={platform.id}>
                          {platform.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  )}
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Header
