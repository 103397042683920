import React, { useContext } from 'react'
import { ReactComponent as Collapse } from '@resources/svg/collapse.svg'
import PanelContext from '@contexts/Panel'
import MenuItem from './MenuItem/MenuItem'
import { Aside, CollapseButton, SidebarContainer } from './Sidebar.styles'
import IReportmentCategory from '@interfaces/IReportmentCategory'

const Sidebar: React.FC = () => {
  const { categories, collapsed, setCollapsed } = useContext(PanelContext)

  return (
    <SidebarContainer className={'sidebar col-12 ' + (collapsed ? 'col-md-1 collapsed' : 'col-md-2')}>
      <Aside className="sidebar-sticky">
        <CollapseButton className="btn-collapse d-none d-md-block" onClick={() => setCollapsed(!collapsed)}>
          <Collapse />
        </CollapseButton>

        <MenuItem title="Painel" icon="panel" path="/" />

        {categories?.data.map((category: IReportmentCategory) => (
          <MenuItem title={category.name} icon={category.icon} path={`/categorias/${category.id}/relatorios`} key={category.id} />
        ))}
      </Aside>
    </SidebarContainer>
  )
}

export default Sidebar
