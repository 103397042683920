import { useEffect } from 'react'
import IClient from '@interfaces/IClient'
import { hexToRgb } from '@helpers/Utils'

const useTheme = (client: IClient|null): void => {
  useEffect(() => {
    if (!client) return

    const style = getComputedStyle(document.documentElement)

    const primaryColor = client?.primaryColor ?? style.getPropertyValue('--primary-color')
    const secondaryColor = client?.secondaryColor ?? style.getPropertyValue('--secondary-color')

    document.documentElement.style.setProperty('--primary-color', primaryColor)
    document.documentElement.style.setProperty('--secondary-color', secondaryColor)
    document.documentElement.style.setProperty('--primary-color-rgb', hexToRgb(primaryColor))
    document.documentElement.style.setProperty('--secondary-color-rgb', hexToRgb(secondaryColor))
  }, [client])
}

export default useTheme
