import styled from 'styled-components'

export const List = styled.div`
  > .card-body {
    > .row {
      display: flex;
      align-content: stretch;

      > .col {
        > .card {
          height: 100%;
        }
      }
    }
  }
`

export const Sort = styled.div`
  width: 100%;

  > .btn {
    background-color: #fff;
    flex-grow: 1;
    flex-basis: 0;
  }
`
