import React, { useContext, useMemo } from 'react'
import Table from '@components/Table/Table'
import ReportmentContext from '@contexts/Reportment'
import { usePagination, useSortBy, useTable } from 'react-table'
import Pagination from '@components/Pagination/Pagination'

const Data: React.FC<any> = () => {
  const { isLoading, reportment } = useContext(ReportmentContext)

  const { data } = reportment?.data || {
    data: [],
  }

  const columns = useMemo(() => {
    if (data.length > 0) {
      return Object.keys(data[0]).map((key: string) => ({
        Header: key,
        accessor: key,
      }))
    }

    return []
  }, [data])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data: reportment?.data.data || [],
  } as any, useSortBy, usePagination)

  return (
    <div className="card">
      <div className="card-body p-0">
        <div className="table-responsive">
          <Table
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            page={page}
            headerGroups={headerGroups}
            prepareRow={prepareRow}
            isLoading={isLoading}
            emptyMessage="Nenhum registro encontrado."
          />
        </div>
      </div>

      {pageCount > 1 && (
        <div className="card-footer d-flex justify-content-end">
          <Pagination
            page={pageIndex}
            pageCount={pageCount}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            onClick={gotoPage}
          />
        </div>
      )}
    </div>
  )
}

export default Data
