import React, { useContext } from 'react'
import { Sort } from './SortBy.styles'
import PanelContext from '@contexts/Panel'

const SortBy: React.FC<any> = () => {
  const { sortBy, setSortBy } = useContext(PanelContext)

  const handleSortByChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => setSortBy(value)

  return (
    <Sort className="btn-group">
      <input type="radio" id="alphabetical" className="btn-check" value="alphabetical" onChange={handleSortByChange} checked={sortBy === 'alphabetical'} />
      <label className="btn btn-outline-primary pe-2 ps-2" htmlFor="alphabetical">Padrão (A-Z)</label>

      <input type="radio" id="most-accessed" className="btn-check" value="most-accessed" onChange={handleSortByChange} checked={sortBy === 'most-accessed'} />
      <label className="btn btn-outline-primary pe-2 ps-2" htmlFor="most-accessed">Mais acessados</label>

      <input type="radio" id="recently-accessed" className="btn-check" value="recently-accessed" onChange={handleSortByChange} checked={sortBy === 'recently-accessed'} />
      <label className="btn btn-outline-primary pe-2 ps-2" htmlFor="recently-accessed">Recém-acessados</label>

      <input type="radio" id="featured" className="btn-check" value="featured" onChange={handleSortByChange} checked={sortBy === 'featured'} />
      <label className="btn btn-outline-primary pe-2 ps-2" htmlFor="featured">Novidades</label>
    </Sort>
  )
}

export default SortBy
