import React, { useContext } from 'react'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import App from '@layouts/App/App'
import AuthContext, { AuthProvider } from '@contexts/Auth'
import { QueryClient, QueryClientProvider } from 'react-query'
import Reportments from '@screens/Reportments/Reportments'
import Reportment from '@screens/Reportments/Reportment/Reportment'
import { ReportmentProvider } from '@contexts/Reportment'
import Login from '@screens/Login/Login'
import { PanelProvider } from '@contexts/Panel'
import Dashboard from '@screens/Dashboard/Dashboard'

const Authenticated: React.FC<any> = () => {
  const { isAuthenticated, school, year } = useContext(AuthContext)

  if (!isAuthenticated || !school)
    return null

  return (
    <PanelProvider year={year} school={school}>
      <App>
        <Outlet />
      </App>
    </PanelProvider>
  )
}

const Router: React.FC<any> = () => {
  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route element={<AuthProvider />}>
            <Route element={<Authenticated />}>
              <Route index element={<Dashboard />} />

              <Route path="relatorios" element={<Reportments />} />
              <Route path="categorias/:categoryId/relatorios" element={<Reportments />} />

              <Route element={<ReportmentProvider />}>
                <Route path="categorias/:categoryId/relatorios/:reportmentId" element={<Reportment />} />
              </Route>
            </Route>

            <Route path="login" element={<Login />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default Router
