import React, { useContext, useState, useMemo } from 'react'
import AuthContext from '@contexts/Auth'
import { useQuery } from 'react-query'
import * as $Reportment from '@services/Reportment'
import { List } from './Dashboard.styles'
import { Spinner } from 'react-bootstrap'
import Reportment from '@components/Reportment/Reportment'
import PanelContext from '@contexts/Panel'
import SortBy from '@components/SortBy/SortBy'

const Dashboard: React.FC<any> = () => {
  const { school, year } = useContext(AuthContext)
  const { sortBy } = useContext(PanelContext)

  const [ search, setSearch ] = useState<string>('')

  const { isLoading, data: response } = useQuery([ 'reportments', Number(school?.id), Number(year) ], $Reportment.all, {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  const data = useMemo(() => {
    if (!response)
      return []

    const reportments = response.data.filter(reportment => {
      let exists = true

      if (exists && search.length > 0)
        exists = reportment.name.toLowerCase().includes(search.toLowerCase())

      if (exists && school && year !== null)
        exists = reportment.year === year || reportment.year === null

      return exists
    })

    switch (sortBy) {
    case 'most-accessed':
      reportments.sort((a, b) => b.visits - a.visits)
      break
    case 'recently-accessed':
      reportments.sort((a, b) => new Date(b.lastVisitAt).getTime() - new Date(a.lastVisitAt).getTime())
      break
    case 'featured':
      reportments.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
      break
    default:
      reportments.sort((a, b) => a.name.localeCompare(b.name))
      break
    }

    return reportments
  }, [response, school, search, sortBy])

  const handleSearchChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => setSearch(value)

  return (
    <>
      <div className="row align-items-center">
        <div className="col-12 col-md-4 mb-3">
          <h1 className="mb-0">Painel</h1>
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item active" aria-current="page">Painel</li>
          </ol>
        </div>

        <div className="col-12 col-md-8 mb-3">
          <div className="row">
            <div className="col-12 col-md-8">
              <label htmlFor="search">Ordenar por:</label>
              <SortBy />
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group">
                <label htmlFor="search">Pesquisar:</label>
                <input
                  type="text"
                  id="search"
                  name="search"
                  className="form-control"
                  placeholder="Pesquise pelo nome ou descrição"
                  onChange={handleSearchChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <List className="card mb-3">
        <div className="card-body pb-0">
          {data.length > 0 ? (
            <div className="row">
              {data.map(reportment => (
                <div className="col col-12 col-md-3 mb-3" key={reportment.id}>
                  <Reportment reportment={reportment} />
                </div>
              ))}
            </div>
          ) : isLoading ? (
            <div className="d-flex justify-content-center p-3 mb-3 bg-light">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div className="d-flex justify-content-center p-3 mb-3 bg-light">
              Nenhum relatório encontrado.
            </div>
          )}
        </div>
      </List>
    </>
  )
}

export default Dashboard
