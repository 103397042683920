import React from 'react'
import IReportment from '@interfaces/IReportment'
import { useNavigate } from 'react-router-dom'
import { Card } from './Reportment.styles'
import moment from 'moment'

type Props = {
  reportment: IReportment
}

const Reportment: React.FC<any> = ({ reportment }: Props) => {
  const navigate = useNavigate()

  return (
    <Card className="card" onClick={() => navigate(`/categorias/${reportment.reportmentCategoryId}/relatorios/${reportment.id}`)}>
      <figure className="card-img-top mb-0">
        <img src={reportment.images.medium || reportment.category.images.medium || 'https://via.placeholder.com/485x245'} className="img-fluid" alt={reportment.name} />

        {reportment.category && (
          <figcaption>
            <span className="platform">{reportment.category.name}</span>
          </figcaption>
        )}
      </figure>

      <div className="card-header">
        <h6 className="mb-0">{reportment.name}</h6>
      </div>

      <div className="card-body">
        <p className="card-text">{reportment.description || reportment.category?.description}</p>
      </div>

      <div className="d-flex justify-content-between card-footer">
        {reportment.lastVisitAt == null ? (
          <small className="text-muted">Nunca visualizado</small>
        ) : (
          <>
            <small>Visitas: {reportment.visits}</small>
            <small>Última visita: {moment(reportment.lastVisitAt).format('DD/MM/YYYY, HH:mm')}</small>
          </>
        )}
      </div>
    </Card>
  )
}

export default Reportment
