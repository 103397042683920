import styled from 'styled-components'

export const Card = styled.div`
  cursor: pointer;
  border: 1px solid #ccc;

  > .card-img-top {
    position: relative;

    > img {
      width: 100%;
      height: 245px;
      object-fit: cover;
      object-position: center;
    }

    > figcaption {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      padding: 1rem;
      left: 0;
      top: 0;

      > .platform {
        color: #fff;
        border-radius: 4px;
        padding: 0.25rem 0.5rem;
        background-color: var(--primary-color);
      }
    }
  }
`
