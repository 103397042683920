import React, { forwardRef, useRef, useImperativeHandle, useState, useContext } from 'react'
import * as FlexmonsterReact from 'react-flexmonster'
import { Form, GlobalStyle } from './Flexmonster.styles'
import { GoGear } from 'react-icons/go'
import Select from 'react-select'
import ReportmentContext from '@contexts/Reportment'
import * as $Reportment from '@services/Reportment'
import { useMutation } from 'react-query'
import { BiHelpCircle } from 'react-icons/bi'
import { Tooltip } from 'react-tooltip'
import { Spinner } from 'react-bootstrap'
import AuthContext from '@contexts/Auth'
import { FaFileExport } from 'react-icons/fa'

interface Ref {
  refresh: () => void
}

type Props = {
  ref?: React.Ref<Ref>
  name: string
  expandAllData?: boolean
  expandLabel?: string
  style: string
  report: any
}

const Flexmonster: React.FC<any> = forwardRef<Ref, Props>(({ name, style, expandAllData, expandLabel, report }: Props, ref) => {
  const { platformKeys } = useContext(AuthContext)
  const { reportment } = useContext(ReportmentContext)

  const pivotRef = useRef<FlexmonsterReact.Pivot>(null)
  const selectRefs = useRef<any[]>([])

  const [ toolbarDisplay, setToolbarDisplay ] = useState<boolean>(false)
  const [ isLoading, setIsLoading ] = useState<boolean>(false)

  const mutation = useMutation($Reportment.data)

  useImperativeHandle(ref, () => ({
    refresh: () => {
      if (pivotRef.current !== null)
        pivotRef.current.flexmonster.refresh()
    },
  }))

  const toggleToolbar = () => {
    if (pivotRef.current === null)
      return

    if (pivotRef.current.flexmonster.toolbar.toolbarWrapper.style.display === 'none') {
      setToolbarDisplay(true)
      pivotRef.current.flexmonster.toolbar.toolbarWrapper.style.display = ''
    } else {
      setToolbarDisplay(false)
      pivotRef.current.flexmonster.toolbar.toolbarWrapper.style.display = 'none'
    }
  }

  const expandData = () => {
    if (pivotRef.current === null)
      return

    if (expandAllData) {
      pivotRef.current.flexmonster.expandAllData()
    } else if (expandLabel) {
      pivotRef.current.flexmonster.expandData(expandLabel)
    }
  }

  const onReady = () => {
    const theme = document.createElement('link')

    theme.rel = 'stylesheet'
    theme.href = 'https://cdn.flexmonster.com/theme/lightblue/flexmonster.min.css'

    document.head.appendChild(theme)

    if (pivotRef.current === null)
      return

    if (pivotRef.current.flexmonster.toolbar !== undefined)
      pivotRef.current.flexmonster.toolbar.toolbarWrapper.style.display = 'none'

    expandData()
  }

  const onReset = () => {
    if (!reportment)
      return

    setIsLoading(true)

    mutation.mutate([reportment.data.id as number, null], {
      onSuccess: ({ data }) => {
        if (pivotRef.current === null)
          return

        pivotRef.current.flexmonster.setReport({
          ...report,
          dataSource: {
            data,
          },
        })

        expandData()
      },
      onSettled: () => {
        setIsLoading(false)

        for (const ref of selectRefs.current) {
          ref.clearValue()
        }
      }
    })
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!reportment)
      return

    const data = new FormData(event.currentTarget)

    const filters: any = {}

    for (const [ tag, value ] of data.entries()) {
      if (value != null && value !== '') {
        filters[tag] = [
          ...(filters[tag] || []),
          value,
        ]
      }
    }

    if (reportment.data.requiredFilters && Object.keys(filters).length !== reportment.data.filters.length)
      return

    setIsLoading(true)

    mutation.mutate([reportment.data.id as number, filters], {
      onSuccess: ({ data }) => {
        if (pivotRef.current === null)
          return

        pivotRef.current.flexmonster.setReport({
          ...report,
          dataSource: {
            data,
          },
        })

        expandData()
      },
      onSettled: () => {
        setIsLoading(false)
      }
    })
  }

  const getDefaultValue = (filter: any) => {
    if (reportment?.data.requiredFilters)
      return null

    if (filter.data.length === 0)
      return null

    if (filter.defaultValue == null) {
      const item = filter.data[0]

      return {
        value: item.id,
        label: item.name
      }
    }

    const item = filter.data.find(({ id }: any) => id == filter.defaultValue)

    if (item != null) {
      return {
        value: item.id,
        label: item.name
      }
    }

    return null
  }

  const onExport = () => {
    if (pivotRef.current === null)
      return

    pivotRef.current.flexmonster.exportTo('excel', {
      filename: name,
      exportAll: true,
    })
  }

  const filters = reportment?.data?.filters || []

  return (
    <>
      <GlobalStyle custom={style} />

      <div className="d-flex justify-content-between align-items-end gap-1">
        {filters.length > 0 && (
          <Form onSubmit={onSubmit} className="row align-items-end flex-grow-1">
            {filters.map((filter: any) => (
              <div className="col" key={`${filter.id}-${filter.name}`}>
                <div className="form-group mb-3">
                  <label htmlFor={`${filter.id}-${filter.name}`} className="form-check-label d-block">
                    <span className="me-1">{filter.name}:</span>
                    <BiHelpCircle data-tooltip-id={`${filter.id}-${filter.name}-tip`} />
                    <Tooltip id={`${filter.id}-${filter.name}-tip`} place="top">{filter.description}</Tooltip>
                  </label>

                  <Select
                    id={`${filter.id}-${filter.name}`}
                    ref={(ref: any) => {
                      selectRefs.current = [
                        ...selectRefs.current,
                        ref,
                      ]
                    }}
                    name={filter.tag}
                    placeholder="Selecione..."
                    classNamePrefix="react-select"
                    options={filter.data.map(({ id, name }: any) => ({ value: id, label: name }))}
                    defaultValue={getDefaultValue(filter)}
                    isClearable
                    isMulti={filter.multiSelect}
                    required={reportment?.data.requiredFilters}
                    closeMenuOnSelect={false}
                  />
                </div>
              </div>
            ))}

            <div className="col">
              <div className="mb-3">
                <button type="reset" className="btn btn-outline-primary me-3" disabled={isLoading} onClick={onReset}>Limpar</button>

                <button type="submit" className="btn btn-primary" disabled={isLoading}>
                  {isLoading && <Spinner animation="border" size="sm" className="me-1" />} Filtrar
                </button>
              </div>
            </div>
          </Form>
        )}

        <div className="form-group mb-3">
          <button className="btn pe-3 ps-3 btn-outline-primary me-2" onClick={onExport}>
            Exportar para Excel <FaFileExport size={18} />
          </button>

          <button className={'btn pe-3 ps-3 btn-' + (toolbarDisplay ? 'primary' : 'outline-primary')} onClick={toggleToolbar}>
            Modo avançado <GoGear size={18} />
          </button>
        </div>
      </div>

      <div className="z-index-1">
        <FlexmonsterReact.Pivot
          ref={pivotRef}
          toolbar
          useOlapFormatting
          componentFolder="https://cdn.flexmonster.com/"
          beforetoolbarcreated={(toolbar: any) => {
            let tabs = toolbar.getTabs()

            toolbar.getTabs = () => {
              tabs = tabs.filter((tab: any) => {
                return ![
                  'fm-tab-export',
                  'fm-tab-connect',
                  'fm-tab-open',
                  'fm-tab-share',
                  'fm-tab-save',
                ].includes(tab.id)
              })

              tabs.shift()

              return tabs
            }
          }}
          ready={onReady}
          loadingdata={onReady}
          shareReportConnection={{
            url: 'https://olap.flexmonster.com:9500'
          }}
          licenseKey={platformKeys.FLEXMONSTER}
          width="100%"
          report={report}
        />
      </div>
    </>
  )
})

Flexmonster.displayName = 'Flexmonster'

export default Flexmonster
