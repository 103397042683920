import React, { createContext } from 'react'
import IReportment from '@interfaces/IReportment'
import * as $Reportment from '@services/Reportment'
import * as $Chart from '@services/Chart'
import { Outlet, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

type ReportmentContextProps = {
  reportmentId: number
  reportment: AxiosResponse<IReportment, any> | undefined
  charts: AxiosResponse<any[], any> | undefined
  isLoading: boolean
}

const ReportmentContext = createContext<ReportmentContextProps>({ } as ReportmentContextProps)

export const ReportmentProvider: React.FC = () => {
  const { reportmentId } = useParams()

  const { isLoading, data: reportment } = useQuery([ 'reportment', Number(reportmentId) ], $Reportment.find, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
  })

  const { data: charts } = useQuery([ 'charts', Number(reportmentId) ], $Chart.all, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
  })

  return (
    <ReportmentContext.Provider
      value={{
        reportmentId: Number(reportmentId),
        reportment,
        charts,
        isLoading,
      }}
    >
      <Outlet />
    </ReportmentContext.Provider>
  )
}

export default ReportmentContext
